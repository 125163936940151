.twocol_right {

    .cms_module_title {
        color: var(--bs-primary);
        //display: none;
    }

    nav.main_nav {
        padding-left: 0px;
        background-color: transparent;
        height: auto;
        position: static;
        min-height: unset;

        @media (max-width: map-get($grid-breakpoints, xl)) {
            display: none;
        }

        ul {
            align-items: unset;
        }

        .collapse-link {
            display: inline;

            i {
                margin-top: 9px;
                transform: rotate(-90deg);
            }

            &.collapsed {

                i {
                    transform: rotate(90deg);
                }

            }
        }

        .links {
            //border-bottom: 1px dotted var(--bs-primary);
            a {
                &:first-child {
                    margin-right: 13px;
                }
            }

            .collapse-nochildren {
                width: 8px;
                display: inline;
            }
        }




        .submenu {

            &.submenu_2 {
                &.show {
                    display: unset;
                }
            }

            ul {
                column-count: unset;
                padding: 0px 25px;
                margin-bottom: -5px;

                li {
                    padding: 5px 0px;
                }

                a {
                    color: var(--bs-secondary);
                    font-weight: normal;

                    &:hover {
                        color: var(--bs-primary);
                    }
                }
            }


        }

        li {
            a {
                color: var(--bs-secondary);
                font-size: 16px;
                font-weight: 600;
            }

            .links {
                &:first-child {
                    a {
                        &:hover {
                            color: var(--bs-primary);
                        }
                    }
                }
            }

            &::before {
                content: '';
            }

            &:hover {

                &::before {
                    content: '';
                }

            }

        }
    }

    .portlet_content {
        img {
            //width: 100%;
            height: auto;
        }
    }
}