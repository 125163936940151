/* landing page studiji */

//studiji landing
.bodycms_studiji_landing {
    color: var(--bs-primary);

    .orange_area {
      margin-bottom: 70px;
    }

    iframe {
      width: 995px !important;
      height: 572px !important;

      @media (max-width: map-get($grid-breakpoints, xl)) { 
        width: 700px !important;
        height: 550px !important;
      }

      @media (max-width: map-get($grid-breakpoints, md)) { 
        width: 500px !important;
        height: 300px !important;
      }

      @media (max-width: map-get($grid-breakpoints, sm)) { 
        width: 280px !important;
        height: 200px !important;
      }

    }

    .news_show_headlines_render_articles a.rounded-pill:hover {
      background-color: black;
      color: white;
    }

    p {
      word-wrap: anywhere;
    }

    .block_content .js-sidebar {
      display: none;
    }
 
   .middle3_area_white .portlet_carousel {
     margin-bottom: 70px;
   }
 
   #cms_area_carousel {
     .carousel {

      .container {
        position: relative;
        height: 500px;

        @media (min-width: 768px) and (max-width: 1200px) {
          height: 250px;
        }
        .carousel-caption {
          justify-content: space-between;
        }
      }

      img {
        filter: brightness(20%);
      }
 
      h1 {
        font-size: 80px !important;
      //line-height: 44px;
        width: 30%;
        text-align: left;

        @media (max-width: map-get($grid-breakpoints, xl)) {
        font-size: 40px !important;
        //line-height: 44px;
        width: unset;
        text-align: left;
        margin-left: 0;
      }
      }
      p {
        font-size: 40px;
        line-height: 50px;
        color: white;
        width: 55%; //40%
        text-align: left;
        font-weight: bold;

        @media (max-width: map-get($grid-breakpoints, xl)) {
        font-size: 22px;
        line-height: 32px;
        width: unset;
      }
      }

       .item {
        @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, xl)) {
          height: 250px !important;
        }
        @media (max-width: map-get($grid-breakpoints, md)) {
          height: 400px !important;
        }
       }

      .carousel-caption {
        @media (max-width: map-get($grid-breakpoints, md)) {
          top: 10%;
          display: unset;
          text-align: left;
        }
        @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, xl)) {
          display: unset;
          text-align: left;
          top: 20%;
        }
      }
     }

   }
 
 
   #area_contact {
     @media (min-width: 2000px) {
       .carousel {
         max-width: 2000px;
         margin: 0 auto;
         margin-bottom: 0px;
       }
     }
 
     .portlet_carousel .container-full {
       background: #1c8ea6;
       max-width: 2000px;
       padding: 0;
     }

    @media (max-width: map-get($grid-breakpoints, lg)) {
        img {
          display: none;
        }
    }
 
     .carousel-description-inner {
      color: white;
      padding-left: 35% !important;
      @media (max-width: map-get($grid-breakpoints, xl)) {
        padding-left: 25px !important;
      }


 
       h1{
         color: white;
       }
 
       p {
         margin-bottom: 1rem;
         color: white;
       }
       a.rounded-pill {
         color: white;
         border-color: white;
         font-size: 14px;
         line-height: 30px;
       }
     }
   }
 
   .lead_block {
     font-size: 28px;
     line-height: 48px;
     color: var(--bs-primary);

      @media (max-width: map-get($grid-breakpoints, xl)) {
        font-size: 18px;
        line-height: 28px;
        padding: 0 0 0 15px;
      }
   }
 
   .studiji_priznati {
     color: var(--bs-primary) !important;
     margin-top: 70px;
     h2 {
       font-size: 40px;
       line-height: 60px;

       @media (max-width: map-get($grid-breakpoints, xl)) { 
        font-size: 28px;
        line-height: 32px;
       }
     }
     h3 {
       font-size: 30px;
       line-height: 48px;
       @media (max-width: map-get($grid-breakpoints, xl)) { 
        font-size: 22px;
        line-height: 38px;
       }
     }
 
     p {
       font-size: 22px;
       line-height: 40px;
       @media (max-width: map-get($grid-breakpoints, xl)) { 
        font-size: 18px;
        line-height: 26px;
       }
     }
   }
 
   .studiji_blue_content {
     color: white !important;
 
     h3 {
       max-width: unset;
       font-size: 40px;
       line-height: 60px;
       margin-bottom: 20px;

       @media (max-width: map-get($grid-breakpoints, xl)) { 
        font-size: 28px;
        line-height: 28px;
       }
     }
 
     p {
       font-size: 22px;
       line-height: 40px;
       color: white !important;
       margin-bottom: 30px;

       @media (max-width: map-get($grid-breakpoints, xl)) { 
        font-size: 18px;
        line-height: 26px;
       }
     }
 
     a.rounded-pill {
       color: white;
       border-color: white;
       font-size: 14px;
       line-height: 30px;
     }

     a.rounded-pill:hover {
      background-color: white;
      color: #1c8ea6;
    }
   }
 
   .black_area_links {
     color: white;
     h3 {
       line-height: 60px;
       margin-bottom: 20px;

       @media (max-width: map-get($grid-breakpoints, xl)) { 
        font-size: 28px;
        line-height: 28px;
       }
     }
     p {
       font-size: 22px;
       line-height: 40px;
       color: white !important;
       margin-bottom: 30px;

       @media (max-width: map-get($grid-breakpoints, xl)) { 
        font-size: 18px;
        line-height: 26px;
       }
     }
 
     a.rounded-pill {
       color: white;
       border-color: white;
       font-size: 14px;
       line-height: 30px;
     }
   }
 
   .middle3_area_white .carousel {
     h1 {
       font-size: 40px;
       line-height: 44px;
        color: var(--bs-primary);

        @media (max-width: map-get($grid-breakpoints, xl)) { 
          font-size: 28px;
          line-height: 28px;
         }
     }
     p {
       font-size: 18px;
       line-height: 26px;
        color: var(--bs-primary);
     }
   }
 
   .studiji_yellow_first {
      color: var(--bs-primary);
 
     h2 {
       font-size: 40px;
       line-height: 50px;
       color: var(--bs-primary);

       @media (max-width: map-get($grid-breakpoints, xl)) { 
        font-size: 28px;
        line-height: 34px;
       }
     }
 
     p {
       font-size: 22px;
       line-height: 40px;
       color: var(--bs-primary);

       @media (max-width: map-get($grid-breakpoints, xl)) { 
        font-size: 18px;
        line-height: 26px;
       }
     }
   }
 
   .studiji_yellow_second {
      color: var(--bs-primary);
 

    
    h2 {
      font-size: 40px;
      line-height: 60px;
      color: var(--bs-primary);

      @media (max-width: map-get($grid-breakpoints, xl)) { 
      font-size: 28px;
      line-height: 34px;
      }
    }
 
    p {
      font-size: 18px;
      line-height: 28px;
      color: var(--bs-primary);

      @media (max-width: map-get($grid-breakpoints, xl)) { 
      font-size: 16px;
      line-height: 26px;
      }
    }
   }
 
   .studiji_last_white_content {
     margin-top: 70px;
      color: var(--bs-primary);
 
     h2 {
       font-size: 40px;
       line-height: 60px;
       color: var(--bs-primary);
       @media (max-width: map-get($grid-breakpoints, xl)) { 
        font-size: 28px;
        line-height: 28px;
       }
     }
 
     p {
       font-size: 22px;
       line-height: 40px;
       color: var(--bs-primary);
       @media (max-width: map-get($grid-breakpoints, xl)) { 
        font-size: 18px;
        line-height: 26px;
       }
     }
 
     h5 {
       font-size: 30px;
       line-height: 40px;
       color: var(--bs-primary);

       @media (max-width: map-get($grid-breakpoints, xl)) { 
        font-size: 22px;
        line-height: 40px;
       }
     }

     a:hover {
      text-decoration: underline !important;
     }
 
 
   }


  
  @media (max-width: map-get($grid-breakpoints, md)) { 
    a.rounded-pill {
      padding: 5px 15px !important; 
    }
    
  }
   
 
 }
 
 
 
 
 /* END landing page studiji */
 