// -------------------
// NEW REPOSITORY
// -------------------
.filemanager {
    width: 100%;
    position: relative;



/*-------------------------
    Breadcrumps
-------------------------*/


    .filemanagerbreadcrumbs {
        color: #333;
        margin-right:25px;
        font-size: 24px;
        font-weight: 700;
        line-height: 35px;
        margin-bottom: 1em;
        a:link, .filemanagerbreadcrumbs a:visited {
            color: #333;
            text-decoration: none;
        }
        a:hover {
            text-decoration: underline;
        }
    }


    /*-------------------------
        Search box
    -------------------------*/


    .search {
        position: absolute;
        padding-right: 30px;
        cursor: pointer;
        right: 0;
        font-size: 17px;
        color: #000;
        display: block;
        width: 40px;
        height: 40px;
        top: -6px;

        input[type=search] {
            border-radius: 2px;
            color: #4D535E;
            background-color: #ccc;
            width: 250px;
            height: 44px;
            margin-left: -215px;
            padding-left: 20px;
            text-decoration-color: #4d535e;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            display: none;
            outline: none;
            border: none;
            padding-right: 10px;
            -webkit-appearance: none;
        }
    }

    ::-webkit-input-placeholder { /* WebKit browsers */
        color:    #4d535e;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:    #4d535e;
        opacity:  1;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:    #4d535e;
        opacity:  1;
    }
    :-ms-input-placeholder { /* Internet Explorer 10+ */
        color:    #4d535e;
    }


    /*-------------------------
        Content area
    -------------------------*/


    .resultitem {
        padding-bottom: 10px;
        border: 2px solid white;
    }

    .resultitem:hover {
        background-color: #e0e0e0;
    }

    .data {
        z-index: -3;
        display: flex;
        flex-flow: row wrap;
        .resultitem {
            display: flex;
            //margin-bottom: 10px;
        }
        div {
            &.icon-pos {
                text-align: center;
                display: table-cell;
                vertical-align: middle;
                padding: 0 10px 0 0;
            }

            div.details {
                border-radius: 0px;
                background-color: transparent;//#f0f0f0;
                height: auto;
                width: 100%;
                list-style-type: none;
                cursor: pointer;
                box-sizing: border-box;
                //transition: 0.3s background-color;
                margin-bottom: 2px;
                padding-top:10px;
                &:hover {
                    //background-color: #e0e0e0;

                }

            }
            .name {
                color: #333;
                font-size: 15px;
                font-weight: 700;
                line-height: 1.5em;
                white-space: nowrap;
                display: contents;
                /*position: absolute;*/
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
            }
            span.details {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                //width: 100%;
                display: none;
            }
            span.downloadZipFile {
                &:hover {
                    color: white;
                    //background-color:  var(--bs-primary);
                    padding-left: 3px;
                    padding-right: 3px;
                }

                padding-left: 1em;
            }
        }
        li .details {
            color: #555;
            font-size: 13px;
            font-weight: 400;
            width: 55px;
            height: 10px;
            top: 1.5em;
            white-space: nowrap;
            position: absolute;
            display: inline-block;
        }
    }

    .nothingfound .nofiles {
        text-align: center;
        font-size: 10em;
        color: #d72f6e;
        display: block;
    }

    .folder-icon, .file-icon {
        font-size: 2em;
        margin: 0 10px 0 0 !important;
        display: table-row;
    }

    .fa-file-pdf-o {
        color: #b01a09;
    }
    .fa-file-word-o {
        color: #072b8e;
    }
    .fa-file-archive-o {
        color: #07618e;
    }
    .fa-external-link, .fa-file-excel-o {
        color: #078e2f;
    }
    .fa-file-text-o {
        color: #000;
    }
    .fa-file-image-o {
        color: #a3ac09;
    }
    .fa-file-o {
        color: #7e078e;
    }
    .fa-file-powerpoint-o {
        color: #af5409;
    }

    .files.notvisible .name, .folders.notvisible .name {
        font-weight: 100;
    }

    .files.notvisible .name, .folders.notvisible .name, .files.notvisible .details, .folders.notvisible .details {
        color: #777;
        font-style: italic;
    }

    .resultitemFolder{
        background-color: #f1f1f1;
    }

    .resultitemFile{
        border-bottom: 1px dotted #ccc;
    }
} //END OF NEW REPOSITORY

//VERZIJA 1 repozitorija:

.repository {
    clear:none;
    //font-size: 16px;
    color: #231f20;
}

.repository ul{
    list-style-type:none;
    padding:0;
    margin:0;
}

.repository ul li ul {
   //margin-left:15px;
    padding-left:15px;
}

.repository ul li{
    margin-left:0px;
}

.repository .entry{
    padding:1px;
    display:block;
    clear:both;
}

.repository .image{
    margin:0;
    padding-left:5px;
    float:left;
    max-height: 26px;
    max-width:26px;
    min-height: 26px;
    min-width:26px;
    background-size: 100% !important;
}

.repository .link {
    padding:3px 4px 4px 40px;
}

.repository .link:hover a {
    cursor:pointer;
    //text-decoration:underline;
    color: #231f20;

}

div.portlet_repository{
    //font-size:13px;
}
div.portlet_repository div.content{
   // font-size:13px;
    margin-left:1px;
    color: #9d0a0e; 
}
div.portlet_repository span.cms_info_about_authortime{
    font-size:10px;
    color: #aaa;
}
div.repository div.image{
    margin-top:4px;
}
div.repository div.head{

}
div.repository .closed {
    background-color: white;
}
div.repository .closed div.arrow{
    display:block;
    position:relative;
    width:12px;
    height:12px;
    top:12px;
    left:7px;
    cursor:pointer;

    div.arrow::after {
        content: "+";
        font-size: 20px;
    }
}
div.repository .closed div.arrow::after {
    content: "+";
    font-size: 20px;
}
div.repository .open {
    background-color: #f7f7f7;
}
div.repository .open div.arrow{
    display:block;
    position:relative;
    width:12px;
    height:12px;
    top:12px;
    left:7px;
    cursor:pointer;
}

div.repository .open div.arrow::after {
    content: "-";
    font-size: 20px;
}
div.repository div.open div.arrow{
    background-position: bottom;
}

.repository a {
    text-decoration:none;
}
.repository .content:after {
    clear:both;
}

.side .portlet_repository .repository{
    font-size: 14px;
    .link .cms_info_description, .link .cms_info_about_authortime{
        display:none;
    }
}

.rep_folder {
    background: #eeddd5;
    .link a {
        color: black;
    }
    border: 1px solid #e0b7a3;
    @include border-radius() ;
}