.black_area_links {
  padding: 40px 0px;
  background-color: #353A48;
  color: white !important;

  ul li a:hover {
    text-decoration: underline !important;
  }

  a.rounded-pill {
    color: white !important;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 30px;
    border: 2px solid;
  }

  a.rounded-pill:hover {
    color: black !important;
    background-color: white;
    text-decoration: none;
    border: 2px solid black;
  }
  a {
    color: white;
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: 20px;
  }

  h3 {
    color: white !important;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 35px;
    @media (max-width: map-get($grid-breakpoints, md)) {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }
  .col-md-6 p {
    color: white !important;
    font-size: 30px;
    line-height: 48px;
    @media (max-width: map-get($grid-breakpoints, md)) {
      font-size: 20px;
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    justify-content: space-between;
    margin-bottom: 0;
    color: white !important;
    padding-left: 25%;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      flex-direction: column;
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
      padding-left: 0;
    }

    li {
      padding-bottom: 44px;
      color: white;
      font-size: 28px;
      font-weight: bold;

      @media (max-width: map-get($grid-breakpoints, md)) {
        font-size: 20px;
        padding-bottom: 22px;
      }
    }

   

    img {
      margin-right: 24px;
    }
  }

  .col-md-4 p {
    color: white !important;
    font-size: 16px;
    margin-bottom: 2rem;
    line-height: 24px;
    @media (max-width: map-get($grid-breakpoints, md)) {
      font-size: 14px;
    }
  }

  .col-md-4 p:last-of-type {
    padding-top: 1.5rem;
  }

  .col-md-4 h5 {
    color: white !important;
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
    @media (max-width: map-get($grid-breakpoints, md)) {
      font-size: 18px;
    }
  }
}

