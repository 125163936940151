@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

$font: Inter;
$fixed-header-height: auto;
$quilt-header-height: 100px;
$header-height: 100px;
$header-height-mobile: 80px;

html,
body {
  font-family: $font;
  background-color: $white;
  font-size: 16px;
  color: black;
  margin: 0;
  padding: 0;
}

h1 {
  font-weight: bold;
  font-size: 48px;
  margin-bottom: 46px;
}

h2 {
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 21px;
}

h5 {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 21px;
}


p {
  font-size: 18px;
  line-height: 28px;
  color: var(--bs-primary);
}

.breadcrumb {
  background-color: transparent;
  padding-left: 0px;


  li {

    margin-right: 10px;

    a {
      font-size: 10px;
      font-weight: bold;
      color: var(--bs-primary);
    }

    &::after {
      content: '>';
      font-size: 8px;
      margin-left: 10px;
      vertical-align: middle;
    }

    &:last-child {
      margin-right: 0;

      &::after {
        content: '';
        margin-left: 0px;
      }
    }
  }

  .crumb_current a {
    color: var(--bs-primary);
  }
}

a#accessibility_opener {
  display: none;
}

.mobile-menu {
  display: none;
  flex-direction: row;
  justify-content: space-between;

  .mobile-menu-item {
    padding: 10px;
  }

  @media (max-width: map-get($grid-breakpoints, xl)) {
    display: flex;
  }
}

@media (min-width: map-get($grid-breakpoints, xl)) {
  .horizontal_nav_div, .accessibility_menu {
    margin-top: 28px;
  }
}
#search_bar.search {
  display: none;
  top: $header-height;
  position: fixed;
  width: 100%;
  background-color: var(--bs-primary);
  z-index: 20;
  padding: 20px 0px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, .07);

  @media (max-width: map-get($grid-breakpoints, xl)) {
    top: $header-height-mobile;
  }

  .search-input {
    display: flex;

    i {
      padding: 11px;
      color: white;
    }

    input {
      @include border-radius(0px);
      width: 100%;
      color: white;
      background-color: transparent;
      border: unset;
      border-bottom: 1px solid white;

      &::placeholder {
        color: white;
      }
    }
  }
}


.fixed_header {
  .horizontal_nav_div {
    /* SMALLER MOBILE SCREEN */

    @media (max-width: map-get($grid-breakpoints, xl)) {

      .portlet_nav {

        .links {
          position: relative;
          display: flex;
          align-items: center;
        }

        .nav-link {
          padding-top: 6px;
          padding-bottom: 6px;
          padding-left: 0;
          padding-right: 20px;
        }

        a {
          color: white !important;
        }

        ul.nav {
          padding-left: 10px;
          flex-direction: column;

          li {

            font-size: 18px;

            .collapse-link, .collapse-nochildren {
              display: block !important;
              width: 20px;
              text-align: center !important;
              transform: rotate(180deg);
              transition-duration: 0.2s;
              margin-right: 3px;
            }

            .collapse-link.collapsed {
              transform: rotate(0deg);
              transition-duration: 0.2s;
            }


            & > .submenu {
              font-weight: normal !important;
              padding-left: 20px !important;
            }

            a.nav-link {
              padding: 10px !important;
            }
          }
        }
      }
    }
  }
}



h1,
h2,
h3,
.cms_module_title {
  font-family: $font;
  color: var(--bs-primary);

  a {
    color: var(--bs-primary);

    &:hover {
      text-decoration: none;
    }
  }
}

.block_content {
  margin-top: 70px;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    margin-top: 0px;
  }
}

.bodycms_frontpage .block_content {
  margin-top: 0;
}

.cookie-message-container {
  background: rgba(32, 32, 32, 0.9);
  padding: 10px 20px;
  color: white;
}




.js-mobile-nav {
  @media (min-width: map-get($grid-breakpoints, xl)) {
    display: none;
  }
}


.submenu_2 {
  &.show {
    @media (min-width: map-get($grid-breakpoints, xl)) {
      display: none;
    }
  }
}



.accessibility_menu {
  @media (min-width: map-get($grid-breakpoints, xxl)) {
    margin-right: 50px;
  }

  @media (max-width: map-get($grid-breakpoints, xl)) {
    display: block;
    position: absolute;
    right: 15%;
  
  }
  @media (min-width: map-get($grid-breakpoints, sm)) {
    min-width: 170px;
  
  }

  .multilang {
    @media (max-width: map-get($grid-breakpoints, sm)) {
      display: none !important;
    
    }
  }

  ul.nav {
    .nav-item {
      display: block;

      img {
        margin: 0 5px;
      }
    }

    .social {
      a {
        @extend .rounded-circle;
        display: inline-block;
        text-align: center;
        color: var(--bs-primary);
        background: white;
        vertical-align: middle;

        svg {
          vertical-align: top;
        }

        &:hover {
          color: var(--bs-primary);

          path {
            fill: var(--bs-primary);
          }
        }

        i {
          font-size: 22px;
        }
      }

      padding: 5px;
    }
  }
}

.fixed_header {
  position: fixed;
  z-index: 30;
  width: 100%;
  height: $fixed-header-height;
  background-color: white;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.07);


  a {
    color: white;
    text-decoration: none;
  }

  .fixed_nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    background: var(--bs-primary);
    height: 54px;

    div.faculty_name {
      font-size: 14px;

      img {
        background-color: #f5f5f5;
        margin-left: -40px;
        padding-left: 40px;
        margin-right: 20px;
        padding-right: 20px;

      }
    }
  }

  .fer_logo {
    @media (max-width: 450px) {
        width: calc(100vw - 200px);
    }
  }


  .quilt_nav {
    overflow: hidden;

    .full-lang-name {
      display: none;
    }

    .lang_link {
      position: relative;

      &:hover {
        .full-lang-name {
          position: absolute;
          display: block;
          background-color: var(--bs-primary);
          color: white;
          top: -22px;
          padding: 4px 6px;
          overflow: unset;
          left: -18px;
          font-size: 10px;
          @include border-radius(2px);
        }
      }
    }


    .multilang {
      display: flex;
      height: auto;
      margin-left: 15px;
      font-family: $font;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700;
      margin-top: 2px;

      a {
        color: var(--bs-primary);

        &:hover {
          color: var(--bs-primary);
        }
      }

      .w3c-lang-name {
        display: flex;
      }

      .full-lang-name {
        display: none;
      }

      padding: 10px;
    }

    .mobile-menu {
      .multilang {
        margin-left: unset;
        align-items: center;
        margin-top: unset;
        a {
          color: var(--bs-primary);

          &:hover {
            color: var(--bs-primary);
          }
        }

      }
    }

    @media (min-width: map-get($grid-breakpoints, xxl)) {
      .logo {
        
        margin-left: 50px;
      }
    }

    @media (min-width: map-get($grid-breakpoints, xl)) {
      height: $quilt-header-height;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background: white;
      align-items: center;

      .logo {
        display: flex;
        align-items: center;

        .name,
        .hamburger {
          display: none;
        }
      }



      .horizontal_nav_div {
        display: flex;
        align-items: center;
      }
    }

    @media (max-width: map-get($grid-breakpoints, xl)) {
      height: $header-height-mobile;

      .logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: var(--bs-primary);
        font-size: 16px;
        height: $header-height-mobile;

        a {
          color: var(--bs-primary);
        }

        img {
          margin: 10px;
        }

        .hamburger img,
        .accessibility_open_btn img,
        .search_open_btn img {
          height: 20px;
          margin: 15px;
          cursor: pointer;
        }
      }

      .eu_fullscreen {
        display: none;
      }

      .eu_mobile {
        display: block;
        height: auto !important;
      }

      width: 100%;
      display: flex;
      flex-direction: column;
      background: white;

      #horizontal_nav_div {
        display: none;
        #horizontal_nav_mobile_quick_icons {

          .mobile_nav_quick_link {
            float: left;
            color: white;
            padding-right: 10px;
            &:hover {
              cursor: pointer;
            }
          }
          .clear {
            clear:both;
          }
        }
      }

    
    }
  }
}
#area_side_portlets nav.main_nav  .nav-link.current_page {

  @media (min-width: map-get($grid-breakpoints, xl)) {
    border-bottom: 2px solid black;
    color: black;
  }

  a {
    color: black;
  }
  
}

#area_side_portlets nav.main_nav  a:hover {
  text-decoration: underline !important;
}
nav.main_nav {
  font-family: $font;
  font-size: 24px;
  font-weight: bold;

  background: #373C4A;


  @media (max-width: map-get($grid-breakpoints, xl)) {

    height: 100%;
    overflow: scroll;
  }



  &>ul>li {
    padding: 5px 0;
  }

  .nav-link.current_page {
    color: #ECB000;

  }

  color: white;

  a {
    color: white;

    &:hover {
      color: #ECB000;
    }
  }

  padding: 15px 30px;



  ul {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: map-get($grid-breakpoints, xl)) {
      align-items: unset;
    }
  }

  .submenu {
    padding: 0 !important;
    ul {
      align-items: unset;
    }
  }

  li {
    .nav-link {
      padding: 5px 0;
      flex: 1;
    }

    .links {
      display: flex;
      position: relative;
      justify-content: space-between;
    }

    

    @media (min-width: map-get($grid-breakpoints, xl)) {
      .collapse-link,
    .collapse-nochildren {
      display: none;
    }
    }

    @media (max-width: map-get($grid-breakpoints, xl)) {
      .collapse-link, .collapse-nochildren {
        margin-top: 5px;
        margin-right: 8px;
      }

      &::before {
        display: none;
      }
    }

    .collapse-link {

      i {
          transform: rotate(-90deg);
      }

      &.collapsed {

          i {
              transform: rotate(90deg);
          }

      }
  }

  }

  .submenu ul {
    padding: 30px 80px;
   

    @media (min-width: map-get($grid-breakpoints, xl)) {
      //width: 44%;
      //margin: 0 auto;
    }

    @media (max-width: map-get($grid-breakpoints, xl)) {
      .collapse-link, .collapse-nochildren {
        margin-top: 0px;
      }
    }



    @media (max-width: map-get($grid-breakpoints, xl)) {
      padding: 0px 26px;
    }

    &:has(> :nth-child(10)) {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      display: block;

      @media (max-width: map-get($grid-breakpoints, xl)) {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
  }
    }

    .submenu ul {
      padding: 0px 28px;
      column-count: unset;
    }

    li {
      border: 0;

      -moz-page-break-inside: avoid;
      -webkit-column-break-inside: avoid;
      break-inside: avoid;
      -o-column-break-inside: avoid;
      -ms-column-break-inside: avoid;
      column-break-inside: avoid;
      page-break-inside: avoid;
    }

    .links {
      display: flex;
      flex-direction: row;
      justify-content: left;
    }

    .nav-link {
      font-size: 16px;
      font-weight: 600;
      text-transform: none;
      padding-left: 0;

      &:hover {
        color: #ECB000;
      }
    }

    .nav-item {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 20px;
        left: -25px;
        width: 15px;
        height: 15px;
        background-image: url(../../../icons/navigation_arrow_right.svg);
        background-repeat: no-repeat;
        background-position: center;

        @media (max-width: map-get($grid-breakpoints, xl)) {
          top: 10px;
        }
      }

      &:hover {
        &::before {
          color: var(--bs-primary);
        }
      }
    }

    .submenu .nav-link {
      text-transform: none;
    }
  }

  .public_page::before,
  .private_page::before {
    position: absolute;
    left: -10px;
    top: 16px;
    height: 3px;
    width: 3px;
    content: " ";
    display: block;
  }

  .public_page.present_page::before {
    border-left: 3px solid green;
  }

  .private_page.present_page::before {
    border-left: 3px solid red;
  }

  .public_page.not_present_page::before {
    border-left: 2px dotted green;
    height: 12px;
  }

  .private_page.not_present_page::before {
    border-left: 2px dotted red;
    height: 12px;
  }


  @media (max-width: map-get($grid-breakpoints, xl)) {
    width: 100%;
    position: absolute;
    left: 0;
    min-height: 85vh;
    padding: 29px 37px;
  }



}

.portlet_portfolio  {
  .cms_module_title {
    display: none;
  }
  blockquote {
    padding: 11px 22px;
    margin: 0 0 22px;
    font-size: 20px;
    border-left: 5px solid #eee;
    .blockquote-footer::before {
      content: none;
    }
  }
  .portfolio_image {
    @extend .img-fluid;
    margin: 0;
  }
  .info_right {

    @media (min-width: map-get($grid-breakpoints, md)) {
      padding-left: 60px;
    }
    @media (max-width: map-get($grid-breakpoints, md)) {
      padding-top: 60px;
    }
  }

  .membership-icons{
    margin-top:15px;
  }

  .portfolio-edit {
    .input_text {
      @extend .row;
    }
    label {
      text-align: left !important;
      justify-content: left !important;
      padding: 20px 0;
    }
  }
  .admin_toolbox {
    margin-bottom: 60px;
  }
  h3, h4 {

    text-transform: uppercase;
    border-left: 4px solid $primary;
    padding-left: 10px;
    margin-top: 30px;
    margin-bottom: 20px;
    background: transparent;
  }
  #person_name {
    margin-top: 0 !important;
  }
  div.cms_table_row_1 {
    background: transparent;
  }
  .more_info > div > div {
    padding: 10px 0;
    border-bottom: 1px solid #f0f0f0;
  }
  .basic_info div.row {
    padding: 10px 0;
    p {
      margin: 0;
    }
  }
  .zvanje, .funkcija {
    strong {
      padding-right: 10px;
    }
  }
}


#cms_area_right nav {
  @media (max-width: map-get($grid-breakpoints, xl)) {
    display: none;
  }
}

#cms_area_horizontal_nav {
  width: 100%;
}

#cms_area_horizontal_nav nav.main_nav {
  @media (min-width: map-get($grid-breakpoints, xl)) {
    background: white;

    .nav-link {
      flex: 1;
      position: relative;
    }

    .nav-item.active>.links a:last-child {
      color: #ECB000;
    }

    &>ul>li>div>a.nav-link {
      margin: 5px 25px;
      @media (max-width: map-get($grid-breakpoints, xxl)) {
        margin: 5px 15px;
      }

      &:hover {
        color: var(--bs-primary);
      }

      &.current_page::after,
      &:hover::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: " ";
        width: 50px;
        height: 10px;
      }

      &.current_page {
        color: #ECB000;
      }

    }

    display: flex;
    flex-direction: row !important;

    &>ul {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }

    &>ul>li>div>a {
      color: var(--bs-primary);
      font-size: 16px;
      font-weight: bold;
      //text-transform: uppercase;
    }

    li {
      border: 0 !important;
    }

    &>ul>li {

      &:hover {
        &>.links {
          &:first-child {
            .nav-link {
              color: #ECB000;
            }
          }
        }
      }
    }

    &>ul>li>.submenu {
      position: absolute;
      background: #353A48;
      left: 0;
      right: 0;
      margin-top: 5px;
      box-shadow: 0px 50px 99px #00000048;

      .nav-link {
        margin: 10px 0px;
      }
    }
  }
}



.scrollable_content {
  position: relative;
  padding-top: $header-height;

  @media (max-width: map-get($grid-breakpoints, xl)) {
    padding-top: $header-height-mobile;
  }

  background: white;
}

.big_header {
  width: 100%;
  text-align: center;
}

.intro {
  background: var(--bs-primary);
  padding: 0;

  .intro_txt {
    font-size: 18px;
    padding: 40px;
    background-color: white;
  }

  .intro_logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }
}


/* Default styles for mobile devices */
.show-on-desktop {
  display: none !important;
}

.show-on-mobile {
  display: block !important;
}

/* Styles for devices with a viewport width of 768px and above (tablets and desktops) */
@media (min-width: 992px) {
  .show-on-desktop {
    display: block !important;
  }

  .show-on-mobile {
    display: none !important;
  }
}

.carousel {
  height: 500px;
  margin-bottom: 0 !important;
  background: #EFEFF0 !important;

  .carousel-item,
  .carousel-inner {
    height: 500px;
    background-color: #EFEFF0;
  }

  .carousel-control-prev,
  .carousel-control-next {
    position: relative;
    z-index: 16; /* Iznad indikatora */
    width: 45px;
    height: 45px;
    color: #333;
    background-color: #5A5F69;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;

    &:hover {
      opacity: 1;
      background-color: black;
    }

    svg {
        fill: white;
        width: 10px;
        height: 20px;
      
    }
  }

  .carousel-indicators-prev-next {
    position: absolute;
    bottom: 10px;
    width: 100%;
    height: 45px;
    margin: 0;
    padding-left: 50%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    @media (max-width: 992px) {
      position: relative;
      justify-content: center;
      padding: 0 25px;
      margin: 20px 0;
    }
    @media (max-width: 576px) {
      justify-content: space-between;
    }
  }
  .controls {
    position: relative;
    display: inline-block;
    width: 110px;
    height: 45px;
    margin-left: 65px;

    @media (max-width: 992px) {
      width: 45px;
      margin: 0;
      overflow: hidden;
    }

    .controls-flex {
      display: flex;
      justify-content: space-between;
    }
  }

  .indicators {
    display: inline-block;
    height: 45px;
    margin: 0 20px;
    @media (max-width: 576px) {
      margin: 0;
    }
  }

  &.carousel_alt {
    background: white !important;
    .carousel-indicators-prev-next {
      padding-left: 0 !important;
      @media (max-width: 992px) {
        position: relative;
        justify-content: center;
        margin: 40px;
      }
      @media (max-width: 576px) {
        justify-content: space-between;
        margin: 30px 25px;
      }
    }
    .controls {
      margin-left: 0 !important;
    }
  }


  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 20px;
    height: 20px;
  }


  .carousel-indicators {
    position: relative;
    display: inline-flex;
    height: 45px;
    align-items: center;
    background: transparent !important;
    margin: 0;

    li {
      height: 13px;
      width: 13px;
      background-color: #A1A1A4;
      margin: 0 15px;


      &.active {
        background-color: var(--bs-primary);
      }
    }
  }

  .carousel-description-inner {
    padding: 63px 54px;
    max-width: 650px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      padding: 25px;
    }

    h1 {
      margin-bottom: 23px;
      font-size: 32px;
      font-weight: bold;

      @media (max-width: map-get($grid-breakpoints, lg)) {
        font-size: 24px;
        margin-bottom: 27px;
      }

      &::after {
        display: none;
      }
    }

    p {
      margin-bottom: 40px;
      font-size: 16px;

      @media (max-width: map-get($grid-breakpoints, lg)) {
        font-size: 14px;
        margin-bottom: 27px;
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, xl)) {
    height: auto;

    .carousel-item,
    .carousel-inner {
      height: auto;
    }
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    height: auto;

    .carousel-item,
    .carousel-inner {
      height: auto;
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    height: auto;

    .carousel-item,
    .carousel-inner {
      height: auto;
    }
  }

  background-size: cover;
  background: white;

  .carousel-inner  img.img-fluid {
    object-fit: cover;
    min-width: 100%;
    min-height: 500px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      min-height: unset;
      height: 400px;
    }
  }

  .rounded-pill {
    color: black;
    border-color: var(--bs-primary);
    font-weight: bold;
    font-size: 14px;
    padding: 10px 30px;
    border: 2px solid black;

    &:hover {
      background-color: black;
      color: white;
    }
  }


  .carousel-caption {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    //color: var(--bs-primary) !important;
    color: white !important;
    text-shadow: 5px 5px 10px black;

    //background: rgba(255,255,255,0.6);
    h1 {
      //color: var(--bs-primary) !important;
      color: white !important;
      text-align: center;
      text-shadow: 5px 5px 10px black;
      margin: 10px;
      font-family: $font;
      font-weight: bold;

      &::after {
        display: none !important;
      }

      font-size: 64px !important;

      @media (max-width: map-get($grid-breakpoints, xl)) {
        font-size: 56px !important;
      }

      @media (max-width: map-get($grid-breakpoints, lg)) {
        font-size: 48px !important;
      }

      @media (max-width: map-get($grid-breakpoints, md)) {
        font-size: 40px !important;
      }

      @media (max-width: map-get($grid-breakpoints, md)) {
        font-size: 32px !important;
      }

    }
  }
}

.page_image {
  height: 250px;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    object-fit: cover;
  }

  p {
    margin: 0;
    text-align: center;
  }

  &>.container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }


  align-items: center;
  background: var(--bs-primary);
  color: white;
  font-size: 42px;
  font-weight: bold;
  font-family: $font;



  @media (max-width: map-get($grid-breakpoints, lg)) {
    font-size: 40px;
    height: 200px;

    &>.container {
      &::before {
        border-bottom: 10px solid white;
        border-left: 10px solid white;
        height: 40px;
        width: 40px;
      }

      &::after {
        border-top: 10px solid white;
        border-right: 10px solid white;
        height: 40px;
        width: 40px;
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    font-size: 36px;
    height: 160px;

    &>.container {
      &::before {
        border-bottom: 8px solid white;
        border-left: 8px solid white;
        height: 35px;
        width: 35px;
      }

      &::after {
        border-top: 8px solid white;
        border-right: 8px solid white;
        height: 35px;
        width: 35px;
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 32px;
    height: 120px;

    &>.container {
      &::before {
        border-bottom: 6px solid white;
        border-left: 6px solid white;
        height: 28px;
        width: 28px;
      }

      &::after {
        border-top: 6px solid white;
        border-right: 6px solid white;
        height: 28px;
        width: 28px;
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, sm)) {
    font-size: 28px;
    height: 100px;

    &>.container {
      &::before {
        border-bottom: 5px solid white;
        border-left: 5px solid white;
        height: 20px;
        width: 20px;
      }

      &::after {
        border-top: 5px solid white;
        border-right: 5px solid white;
        height: 20px;
        width: 20px;
      }
    }
  }
}

button {
  outline: none;
}

.btn:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn:focus {
  outline: none !important;
}

.btn:focus {
  box-shadow: none;
}

h4 {
  font-family: $font;
  width: 100%;
  min-height: 50px;
  background: transparent;
  color: black;
  font-weight: normal;
  font-size: 28px;
  border: 0;
  border-bottom: 3px solid var(--bs-primary);
  text-align: center;

}


.custom1,
.custom2 {
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-height: 55px;
  width: 100%;
  padding: 0 10px;
  background: transparent;
  color: black;
  font-weight: normal;
  font-size: 18px;
  border: 0;
  border: 1px solid #c0c0c0;

  &:hover {
    text-decoration: none;
    color: var(--bs-primary);
    //background: var(--bs-primary);
    border-bottom: 3px solid var(--bs-primary);
  }
}


.dropdown-menu {
  width: 100%;
}

.portlet_ispis_pred {


  .naziv a {
    text-transform: uppercase !important;
  }
  td, th {
    padding-top: 25px;
    padding: 25px;
    font-weight: bold;
    .naziv a {
      text-transform: uppercase;
    }
  }

  tr {
    background: #CDD1D4;
  }

  tr:nth-child(even){background: #EEEFF0;}

  tr.predmet {
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    background-color: var(--bs-primary);
    color: white;
  }

  .predmetispis_seme {
    font-size: 28px;
    color: var(--bs-primary);
    padding-bottom: 18px;
    border: 0;
    background-color: white;
    padding-left: 0;
  }

}

.cms_module {
  .ui-widget {
    font-family: inherit !important;
    font-size: inherit !important;
  }

  .ui-tabs {
    .ui-tabs-panel {
      border: unset;
      border-top: 0;
      padding: 40px 0;
    }

    .ui-tabs-nav {
      border: none;
      border-bottom: unset;
      margin: 0 !important;

      li {
        border: 1px solid var(--bs-primary);
        padding: 0px 30px;
        margin: 0;
        margin-top: 5px;
        @include border-radius(0);
        margin-right: 5px;

        a {
          border: none;
          text-transform: uppercase;
          font-size: 16px;
          font-weight: bold;
          color: var(--bs-primary);
          background-color: transparent !important;
          padding: 10px 5px;
        }

        a:hover {
          color: #4c4c4c;
          text-decoration: none;
          background-color: transparent !important;
        }

        &.ui-state-default {
          background-color: transparent !important;

          &:hover {
            border: 1px solid var(--bs-primary);
            background-color: var(--bs-primary) !important;

            a {
              font-weight: bold;
              color: white;
            }
          }
        }

        &.ui-state-active {
          a {
            font-weight: bold;
            color: white;
          }

          border: 1px solid var(--bs-primary);
          background-color: var(--bs-primary) !important;

          &:hover {
            background-color: var(--bs-primary) !important;
          }
        }
      }
    }
  }
}

.bodycms_frontpage {
  .twocol_side {
    background-color: #f6f6f6;
  }
}

.bodycms_fullwidth {
  .twocol_side {
    background-color: #f6f6f6;
    padding: 40px;
  }

  .twocol_main {
    padding: 40px;
  }
}

footer {
  padding: 40px;
  background-color: #353A48;
  color: white;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: 40px 0PX;
  }


  img.footer_logo {
    width: 308px;
    filter: grayscale(1);


    @media (max-width: map-get($grid-breakpoints, md)) {
      max-width: 70%;
     }

    &:hover {
      filter: grayscale(0);
    }
  }

  p,
  a {
    font-size: 14px;
    color: white;
  }

  a:hover {
    color: white;
    text-decoration: underline !important;
  }

  h5 {
    font-weight: 600;
    margin-bottom: 30px;
  }

  .footer_social {
    ul {
      list-style-type: none;
      padding: 0;
      flex-direction: row;
      -ms-flex-pack: justify;
      justify-content: space-between;
      display: flex;
      max-width: 240px;
      margin: 45px 0px;
    }

    img:hover {
      opacity: 50%;
    }

    &__fb:hover {
      svg {
        path {
          fill: #1877F2;
        }
      }
    }

    &__ln:hover {
      svg {
        path {
          fill: #0a66c2;
        }
      }
    }

    &__yt:hover {
      svg {
        path {
          fill: #FF0000;
        }
      }
    }
  }

  .footer_copy {
    p {
      margin: 0;
      font-size: 10px;
    }
  }

  .footer_contact {
    padding-right: 30px;
  }

  .footer_links {
    ul {
      list-style-type: none;
      padding: 0;

      li {
        margin-bottom: 20px;

        a {
          color: var(--bs-primary);

          &:hover {
            color: var(--bs-primary);
            text-decoration: none;
          }
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

#area_middle,
#area_side_portlets {
  .cms_module {
    margin-bottom: 20px;
  }
}

.portlet_search {
  div.search_div {
    //margin: 15px;
  }

  .cms_module_title {
    display: none;
  }

  margin-top: 40px;

  .search_result {
    margin: 20px 0;
  }

  .dropdown-menu {
    label {
      display: inline !important;
    }

    li {
      padding: 5px 10px;
    }
  }

}



  .portlet_search {

    .resultsItems {
      padding: 5px 0;
      margin-bottom: 5px;

      .resultsItem {
        padding: 5px 0;

        a {
          display: table;
        }
        .icon {
          width: 20px;
          color:  var(--bs-primary);
          display: table-cell;
          vertical-align: middle;
        }

        .title {
          padding-left: 10px;
          color:  var(--bs-primary);
          display: inline-block;
          max-width: 160px;
          font-size: 90%;
        }
      }
    }

    .group {
      border-bottom: 1px solid #b5b5b5;
    }
  }


/*.portlet_content,
.portlet_news {

  ul {
    list-style: none;
  }

}*/

.lead_block,
h4.lead_block,
h5.lead_block,
h3.lead_block h2.lead_block h6.lead_block {
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
  border-left: 3px solid black;
  //margin-bottom: 50px;
  //background-color: var(--bs-primary);
  color: var(--bs-primary);
  padding: 0 30px;
  margin: 0;
  margin-bottom: 25px;

  p {
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    //margin-bottom: 50px;
    //background-color: var(--bs-primary);
    color: var(--bs-primary);
  }


  a {
    color: var(--bs-primary);;
    text-decoration: underline;

    &:hover {
      color: var(--bs-primary);
    }
  }
}

.portlet_news {
  .cms_module_title {
    display: none;
  }

  .read_more_link {
    display: none;
  }

  .news_articles {
    .card {
      border: 0 !important;
      background: transparent;
    }



    .news_article_toolbar {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      margin-top: 26px;

      div {
        margin: unset;
      }

      .news_options {
        padding-bottom: 0;
        a {
          color: var(--bs-primary);
          font-size: 12px;
        }
      }

      .link {
        a {
          color: var(--bs-primary);
          text-transform: unset;
          font-weight: bold;
          font-size: 12px;
        }
      }

      .date {
        font-size: 10px;
        margin-left: 30px;
        margin-top: 2px;
      }
    }

    .pic {
      img {
        object-fit: cover;
        height: 258px;
        //width: 400px !important;
        width: 100% !important;

        @media (max-width: map-get($grid-breakpoints, xl)) {
          height: 270px;
          //width: 270px !important;
        }

        @media (max-width: map-get($grid-breakpoints, lg)) {
          height: 270px;
          //width: 270px !important;
        }

        @media (max-width: map-get($grid-breakpoints, md)) {
          width: 100% !important;
          height: auto !important;
          margin-bottom: 20px;
        }
      }
    }

    .right {
      .title {
        font-family: $font;
        font-weight: normal;

        a {
          color: var(--bs-primary);
        }
      }
    }

    .card-content {
      font-size: 15px;
      color: var(--bs-primary);
    }

    .link {
      display: flex;
      flex-direction: row;
      margin: 20px 0;
      justify-content: space-between;
      align-items: center;

      .dash {
        flex: 1;
        display: block;
        border-bottom: 1px dashed #979797;
        height: 1px;
        margin-right: 20px;
      }

      a {
        font-family: $font;
        font-size: 14px;
        font-weight: normal;
        color: var(--bs-primary);
        text-transform: uppercase;
      }
    }



    .news_admin_links {
      a {
        margin: 10px;
      }
    }
  }

  .date {
    font-family: $font;
    font-weight: normal;
    color: var(--bs-primary);
    margin-bottom: 20px;
  }

  .news_article_full {
    .content {
      margin: 30px 0;
    }

    .pic {
      text-align: center;
      margin-bottom: 30px;
    }
  }

}



.news_show_headlines_render_articles {
  .card {
    border: 0 !important;
    background: transparent;
  }

  .card-body {
    padding: 0;
  }

  .top {
    display: flex;
    flex-direction: column;

    .pic {
      width: auto;
      height: 230px;
      margin-right: 20px;

      @media (max-width: map-get($grid-breakpoints, lg)) {
        height: 270px;
      }

      @media (max-width: map-get($grid-breakpoints, md)) {
        width: 100% !important;
        height: auto !important;
      }

      img {
        object-fit: cover;
        width: 416px;
        height: 230px;

        @media (max-width: map-get($grid-breakpoints, lg)) {
          height: 270px;
        }

        @media (max-width: map-get($grid-breakpoints, md)) {
          width: 100% !important;
          height: auto !important;
        }
      }
    }


  }

  .card-body-content {
    //margin: 22px;
    position: relative;

    .lead {
      font-size: 18px;
    }

    .title {
      margin: 25px 0;
      font-size: 22px !important;

      a {
        color: var(--bs-primary);     

        &:hover {
          text-decoration: none;
          color: var(--bs-primary);
        }
      }
    }

    &--without-image {
      margin-top: 0px;

      h5 {
        font-size: 16px;
      }

     /* &:before {
        content: '';
        width: 2px;
        height: 100%;
        background-color: var(--bs-primary);
        position: absolute;
        left: -21px;
      }*/
    }


    .title {
      color: var(--bs-primary);
    }
  }

  .link {
    display: flex;
    flex-direction: row;
    margin: 20px 0;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    .date {
      font-size: 8px;
    }

    a {
      color: var(--bs-primary);
      font-size: 14px;
      padding: 13px 26px;

      &:hover {
        color: var(--bs-primary);
        text-decoration: none;
      }
    }
  }
}

#area_side_portlets .news_show_headlines_render_articles {
  .card-body {
    padding: 0 !important;
  }
}

.fixed_header .fixed_nav ul.nav .nav-item {
  margin-top: 10px;
}


.portlet_predmet_info .cms_module_title {
  display: none !important;
}


.accessibility_dialog {
  @extend .shadow;
  max-width: 320px;
  width: 320px !important;
  background: white;
  padding: 15px;
  border: none;
  font-family: inherit;
  z-index: 9999;
  position: fixed !important;

  .ui-dialog-title {
    text-transform: uppercase;
  }

  .ui-dialog-titlebar {
    border: none !important;
  }

  .ui-dialog-titlebar-close {
    @extend .rounded;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 15px;
    border: none;
    background: var(--bs-primary) url('../../../icons/close.png') center no-repeat;
    -webkit-background-size: 10px;
    background-size: 10px;
  }

  #accessibility_links {
    max-width: 280px;

    .textsize {
      .gornjilinkoviboxVelicina {
        padding: 10px;
        font-weight: 800;
      }

      .select_size_0 {
        font-size: 11px !important;
      }

      .select_size_1 {
        font-size: 13px !important;
      }

      .select_size_2 {
        font-size: 15px !important;
      }
    }

    .highcontrast {
      .highcontrast_label {
        float: left;
        width: 90%;
      }

      a {
        float: left;
        width: 90%;
        margin-left: 30px;
      }
    }

    .simplelayout {
      .simplelayout_label {
        float: left;
        width: 90%;
      }

      a {
        float: left;
        width: 90%;
        margin-left: 30px;
      }
    }
  }
}

//Accessibility text size:
body.textsize-big {
  font-size: 20px;

  .accessibility_links,
  .ui-dialog-title {
    font-size: 16px !important;
  }

  .multilang {
    margin-top: 0 !important;
  }

  .login_icon .login_link .fa-unlock-alt {
    font-size: 16px;
  }

  .top_link_row>.top_link {
    .fa {
      font-size: 16px;
    }

    a {
      font-size: 16px;
    }
  }

  #cms_area_horizontal_nav .portlet_nav a {
    font-size: 20px;
  }

  #cms_area_right,
  #cms_area_left .portlet_nav .nav-element {
    .nav_link {
      font-size: 22px !important;
      padding: 8px;
    }

    li>ul li {
      padding-top: 0;
    }

    ul>li>a {
      font-size: 22px !important;
      padding: 8px;
    }
  }

  .cms_module_title {
    font-size: 24px;
  }

  .portlet_news {
    .news_article_title h1 {
      .news_title_truncateable {
        font-size: 24px !important;
      }
    }
  }

  .sidepanel .language span.w3c-lang-name {
    font-size: 16px;
    padding: 4px;
  }

  .sidepanel .login a {
    font-size: 20px;
    top: 6px;
  }

  #cms_area_carousel .portlet_carousel .carousel-caption h1 {
    font-size: 56px;
  }

  #cms_area_carousel .portlet_carousel .carousel-caption p {
    font-size: 30px;
  }

  #cms_area_carousel .portlet_carousel .carousel-caption p a {
    font-size: 30px !important;
  }

  #cms_area_middle .portlet_news_show_headlines h5,
  #cms_area_onecol_white .portlet_news_show_headlines h5 {
    font-size: 1.70rem;
  }

  #cms_area_white_left .portlet_content h2 {
    font-size: 2rem;
  }

  .fixed_header .fixed_nav ul.nav .social a {
    width: 35px;
    height: 35px;
  }

  .fixed_header .fixed_nav ul.nav .nav-item {
    margin-top: 26px;
  }

  .fixed_header a {
    font-size: 18px;
  }

  .quilt_nav {
    height: 180px;

    @media (max-width: map-get($grid-breakpoints, xl)) {
      height: inherit;
    }
  }

  .scrollable_content {
    padding-top: 180px;

    @media (max-width: map-get($grid-breakpoints, xl)) {
      padding-top: inherit;
    }
  }


  .carousel-description-inner {
    p {
      font-size: 20px;
    }
  }

  p {
    font-size: 20px;
  }

  .carousel .carousel-description-inner h1 {
    font-size: 36px;
  }

  .carousel .btn-dark {
    font-size: 18px;
  }

  .orange_area__menus ul li a {
    font-size: 24px;
  }

  .usefull_links ul li a {
    font-size: 20px;
  }

  footer a,
  footer p {
    font-size: 18px;
  }


}

//end of .textsize-big
//END accessibility text size

//accessibility simplelayout
body.simplelayout {
  .carousel {
    display: none;
  }

  #cms_area_middle_right {
    .portlet_news_show_headlines {
      script {
        display: none;
      }
    }
  }
}

//END accessibility simplelayout

//accessibility contrast
body.highcontrast-normal {
  color: black !important;

  #accessibility_links a {
    color: black !important;
  }

  .header_columns {
    color: black;
  }

  .top_link_row>.top_link a {
    color: black;
  }

  .accessibility_dialog {
    color: black;

    .ui-dialog-title {
      color: black;
    }

    .ui-dialog-titlebar-close {
      color: black;
    }
  }

  .horizontal-nav-row {
    color: black !important;
  }

  .portlet_nav {
    color: black !important;

    .nav>li>a {
      color: black !important;
    }

    .nav>li>ul>li a {
      color: black !important;
    }
  }

  .header-search_input {
    border: 1px solid $border-color !important;
    color: black;
    background-color: transparent !important;
  }

  .cms_module_title {
    color: black !important;
  }

  .portlet_news_show_headlines {
    .carousel-caption {
      color: black !important;

      .truncate-to-visible {
        color: black !important;
      }
    }
  }

  .carousel-caption {
    color: black !important;

    .truncate-to-visible {
      color: black !important;
    }
  }

  .item .carousel-caption .title a {
    color: black !important;
  }

  .item .carousel-caption h1 a {
    color: black !important;
  }

  div.portlet_repository span.cms_info_about_authortime {
    color: black !important;
  }

  .middle-left-col .portlet_content .main_page_upper_links a,
  .middle-left-col .portlet_content .main_page_upper_links a:focus,
  .middle-left-col .portlet_content .main_page_upper_links a:active,
  .middle-left-col .portlet_content .main_page_upper_links a:hover,
  .middle-left-col .portlet_content .main_page_upper_links a:visited {
    color: black !important;
    font-weight: 800;
  }

  .portlet_news_show_headlines .news_headlines .article .caption h3 a {
    color: black !important;
  }

  #cms_area_middle .portlet_news_show_headlines .news_headlines .article .caption time {
    color: black !important;
  }


  .mega-dropdown-menu {
    background: white !important;
  }

  .search-button,
  .lang_link {
    color: black !important;
  }

  .div_login img,
  .hamburger img {
    filter: invert(100%);
  }

  .mm-listview>li>a,
  .mm-listview>li>span {
    color: black !important;
  }

  #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu a:active,
  #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu a:focus,
  #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu a:hover,
  #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu a:visited {
    color: black !important;
  }

  .header .header-icons .search-separator .search-container input {
    border: 1px solid black;
    background-color: white !important;
    color: black !important;
  }
}

.portlet_djelatnici td a {
  text-transform: uppercase !important;
}

//END accessibility contrast

//accessibility inverted contrast
body.highcontrast-inverted {
  color: white !important;
  background-color: #777;

  a {
    color: white;
  }

  .popup_menu {
    background-color: #777 !important;
  }

  .news_show_headlines_render_articles .card-body-content--without-image {
    margin-top: 22px;
  }

  .usefull_links ul li a {
    color: white !important;
  }


  .portlet_admin_traka {
    .dropdown-menu {
      background-color: #777 !important;

      >li>a {
        background-color: #777 !important;
      }
    }
  }

  // footer, .orange_area, 
  .footer_social, .footer_logo {
    filter: invert(1);
  }

  .orange_area {
    background-color: black !important;
  }

  .news_show_headlines_render_articles .top .pic {
    margin-right: 0px;
  }

  .news_show_headlines_article {
    margin-bottom: 10px;
  }

  .card-body-content h5 {
    color: white !important;
  }

  .card-body-content a {
    color: white !important;
  }

  .carousel {
    background-color: #777 !important;
    color: white !important;
    border-bottom: 1px solid white;
  }

  .carousel p {
    color: white !important;
  }

  .carousel h1 {
    color: white !important;
  }


  .carousel .carousel-inner,
  .carousel .carousel-item {
    color: white !important;
    background-color: #777 !important;
  }

  .logo_div {
    img.normal {
      display: none;
    }

    img.white {
      display: block;
    }
  }

  .header_columns {
    color: white !important;
  }

  .fixed_header {
    background-color: #333 !important;
  }

  #area_onecol_white,
  #area_onecol_silver,
  .twocol_side {
    background-color: #777;
  }

  .news_show_headlines_render_articles .card {
    background-color: #333;
  }

  .above_header .header_logo {
    filter: invert(1);
  }

  .sidepanel {
    background-color: #333 !important;
  }

  .sidepanel .col-12.col-md-12.col-lg-8 .bg-light,
  .sidepanel .col-12.col-md-12.col-lg-4 {
    background-color: #333 !important;
  }

  .sidepanel .language .current_lang span.w3c-lang-name,
  .sidepanel .language span.w3c-lang-name {
    color: white !important;
    border: 1px solid white !important;
  }

  .top_link_row>.top_link a {
    color: white !important;
  }

  .ui-widget-content {
    background-color: #777 !important;
  }

  .logo {
    filter: invert(1);
  }

  p, .card-content, .date {
    color: white !important;
  }

  a {
    color: white !important;
  }

  h1 {
    color: white !important;
  }

  .lead_block {
    background-color: black !important;
    color: white !important;
  }

  .twocol_right nav.main_nav .links {
    border-bottom: 1px dotted white;
}

  .accessibility_dialog {
    span {
      color: white !important;
      font-weight: 800;
    }

    color: white !important;

    .ui-dialog-title {
      color: white !important;
    }
  }

  .horizontal-nav-row {
    color: white !important;
  }

  #cms_area_horizontal_nav nav.main_nav>ul>li>.submenu {
    position: absolute;
    background: #777;
    box-shadow: 0 50px 99px #00000048;
}

  #cms_area_left .portlet_nav .nav_link:hover,
  #cms_area_left .portlet_nav .collapse-link:hover,
  #cms_area_left .portlet_nav .nav-element .collapse-link:hover,
  #cms_area_left .portlet_nav .nav-element:hover {
    background-color: #333 !important;
  }

  div.repository .open,
  div.repository .closed {
    background-color: #333;
  }

  div.repository image {
    filter: invert(1);
  }

  div.repository .closed div.arrow,
  div.repository div.open div.arrow {
    color: white;
  }

  div.portlet_repository div.content {
    color: lightgrey;
  }

  #v1tooltip_div {
    background-color: #333;
  }

  .portlet_subscription .subscicons,
  .portlet_subscription .subs_module {
    color: white !important;
  }

  .portlet_predmet_info .col-md-3 p,
  .portlet_predmet_info .opterecenje_info,
  .portlet_predmet_info div em {
    color: white !important;
  }

  .staff_group,
  .portlet_djelatnici {
    .person {
      color: white;
    }

    //   .staff-thumbnails-container {
    //     float: left;
    //     background: #f9f9f9;
    //     margin: 0px 0px 10px 0px;
    //     padding: 5px;
    //     height: 160px;
    //     width: 400px;
    //     border-right: 10px solid white;
    // }

    // .staff-thumbnails-container h6 {
    //     margin-top: 4px;
    // }

  }


  .header-search_input {
    border: 2px solid white !important;
    color: white !important;
    background-color: transparent !important;
  }

  .cms_module_title {
    color: white !important;
  }

  .portlet_news_show_headlines {
    .carousel-caption {
      color: white !important;

      .truncate-to-visible {
        color: white !important;
      }
    }
  }

  .carousel-caption {
    color: white !important;

    .truncate-to-visible {
      color: white !important;
    }
  }

  .carousel {
    border-color: #777 !important;

    .item {
      background-color: #777 !important;
    }
  }

  input,
  select {
    //border: 2px solid @brand-primary;
    //background-color: @brand-primary;
    color: darken($border-color, 60%);
  }

  tr {
    background-color: darken($border-color, 60%) !important;
    color: white;
  }

  tr:hover {
    background-color: darken($border-color, 60%) !important;
    color: white !important;

    a,
    a:hover,
    a:active,
    a:focus {
      color: white !important;
      font-weight: 800 !important;
    }
  }

  tr:nth-of-type(2n+1) {
    background-color: darken($border-color, 30%) !important;
    color: white;
  }

  tr:nth-of-type(2n+1):hover {
    background-color: darken($border-color, 30%) !important;
    color: white;

    a,
    a:hover,
    a:active,
    a:focus {
      font-weight: 800 !important;
    }
  }

  .predmetispis_lighter {
    color: white;
  }

  .predmetispis_lighter:hover {
    font-weight: bold;
  }

  .item .carousel-caption .title a {
    color: white !important;
  }

  .item .carousel-caption h1 a {
    color: white !important;
  }

  div.portlet_repository span.cms_info_about_authortime {
    color: white !important;
  }

  .middle-left-col .portlet_content .main_page_upper_links a,
  .middle-left-col .portlet_content .main_page_upper_links a:focus,
  .middle-left-col .portlet_content .main_page_upper_links a:active,
  .middle-left-col .portlet_content .main_page_upper_links a:hover,
  .middle-left-col .portlet_content .main_page_upper_links a:visited {
    color: white !important;
    font-weight: 800;
    background-color: #333 !important;
  }

  .portlet_news_show_headlines .news_headlines .article .thumbnail {
    background-color: transparent !important;
  }

  .portlet_news_show_headlines .news_headlines .article .caption h3 a {
    color: white !important;
  }

  #cms_area_middle .portlet_news_show_headlines .news_headlines .article .caption time {
    color: white !important;
  }

  footer {
    background-color: black;

  }

  .fixed_header .quilt_nav,
  #cms_area_horizontal_nav nav.main_nav,
  .scrollable_content {
    background-color: #777;
    background: #777;
  }

  #cms_area_horizontal_nav nav.main_nav>ul>li>div>a,
  .fixed_header .quilt_nav .multilang a {
    color: white !important;
  }

  .footer_contact {
    p, h5 {color: white !important;}
  }
  .footer_copy {
    p, h5 {color: white !important;}
  }
}

//END accessibility inverted contrast

.logged-in {
  .accessibility_menu ul.nav .login-cta {
    display: none;
  }

  #search_bar.search {
    top: $header-height + 56px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      top: $header-height-mobile + 56px;
    }
  }
}

.js-open-sidebar-menu {

  img {
    width: 24px;
    height: 24px;
  }
  cursor: pointer;
  float: right;
  margin-top: 10px;
  margin-right: 10px;
  @media (min-width: map-get($grid-breakpoints, xl)) {
    display: none;
  }
}

.js-sidebar {
  .main_nav {
    overflow: hidden;
  }
  @media (min-width: map-get($grid-breakpoints, xl)) {
    display: none;
  }
}

.repository .link {
  border-bottom: 1px solid silver;
}

.repository .link_icon_html {
  background-image: url(../../../icons/link.png);

  img {
    display: none;
  }
}


.portlet_timeline_content {
  .cd-horizontal-timeline .events a {
    width: 50px;
  }

  .cd-horizontal-timeline .events a.selected {
    background: none;
    border-top: none;
    pointer-events: none;
  }

  .timelineOrderedList {
    padding: 0;
  }

  .timelineOrderedList, .cd-timeline-navigation {
    list-style: outside none none;
  }

  .cd-horizontal-timeline .events-content li > .timelinePic {
    margin-left: 10px;
  }

  .paragraphPictureContainer {
    position: relative;
  }

  .paragraphPictureContainer div p {
    padding-top: 2px;
  }

  .timelinePic {
    position: relative;
    display: inline;
    float: right;
    margin-left: 20px;
    margin-top: 10px;
  }

  .cd-horizontal-timeline .events-content p {

    position: relative;
    display: block;
  }

  .timelineButton {
    color: white !important;
    padding: 3px;
    width: 23px;
  }

  .module_buttons {
    margin-left: -15px;
    }

   .timelineBackAdmin {
    color: white !important;
    left: 6px;
    position: absolute;
    padding: 3px;
    width: 23px;
    }

  .timelineSave {
    padding: 2px;
    width: 23px;
  }
}

#cms_area_middle > .portlet_event_displaycurrent > .cms_module_html > .events_year {
  .title_row {
    text-align: left;
    margin-bottom: 6px;
    h3 {
      //color: @text-color;
      font-size: 20px;
      text-transform: uppercase;
      color: #535353;
    }
  }
  .events_row {
    //text-align: center;
  }

  .flex_row {
    margin: 15px 0px;
    padding-bottom: 12px;
    display: flex;
    color: var(--bs-primary);
    border-bottom: 1px groove var(--bs-primary);

    .date-col {
      padding: 0px;
      width: 120px;
      .date {
        width: 80px;
        height: 80px;
        padding-top: 13px;
        margin-top: 4px;
        .day {
          font-size: 24px;
          text-align: center;
          line-height: 1;
        }
        text-align: center;
        background-color: var(--bs-primary);
        opacity: 0.8;
        color: white;
      }

    }
    .text-col {
      padding: 0px;
      text-align: left;
      .title {
        font-weight: 700;
      }
      span {
        margin: 0;
        float: none;
      }
      .time {
        color: var(--bs-primary);
      }
    }
  }
}

.orange_area  .portlet_event_displaycurrent {
  h3 {
    color: black;
    a {
      color: black;
      &:hover {
       //color: $link-color;
      }
    }
  }

  .kutija {
    @media (max-width: map-get($grid-breakpoints, sm)) {
      margin-bottom: 15px;
    }
  }
  a.rounded-pill {
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
    border: 2px solid black;
    margin-top: 30px; 
  }

  a.rounded-pill:hover {
   background-color: black;
   color: #ecb000;
   border: 2px solid black;
  }
  .cms_module_html > .events {

    .thumbnail {
      border: none;
      background-color: #F8F8F8;
      padding: 0;
    }

    .thumbnail .caption {
     // color: $portlet-bck !important;
    }

    .title {
      color: black;
      font-weight: bold;
    }

    time {
      color: #15A3CF;
    }

    #date {
      color: black;
      display: inline-block;
      padding: 10px 20px;
      background-color: #ECB000;
      text-align: left;
      border: 2px solid #000000;
     
      @media (max-width: map-get($grid-breakpoints, md)) {
        float: left;
        margin-right: 20px;
      }
     
    }

    #url a {
      color: black;
      &:hover {
        //color: $link-color;
      }
    }

    #godina .row .col-xs-4, #godina .row .col-xs-8 {
      width: 100%;
    }

    #month {
      font-size: 12px;
      font-weight: bold;
    }

    #godina {
      text-align: center;
      .title_row h3{
        //color: $brand-primary;
      }
    }

    #title {
      font-size: 24px;
      margin-top: 15px;
      font-weight: bold;

      @media (max-width: map-get($grid-breakpoints, md)) {
        margin-top: 0;
        font-size: 15px;
      }
      
    }

    #day {
      font-size: 40px;
      font-weight: bold;
      line-height: 1;
    }

    #time, #location {
      font-size: 16px;
      color: black;
      //display: inline-block;
    }

    #location a {
      color: black;
    }

    #time {
      #time_start, span, #time_end {
        display: inline-block;
      }
      span:before, span:after {
        content: "\00a0 ";
      }
    }
    #godina .row {
      display: inline-block;
      width: 15vw;
      padding: 15px;
    }

    .carousel-control {
      top: 35px;
      width: auto;
      height: 1em;
      background: transparent;
    }

    .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right {
      position: static;
      display: block;
      width: auto;
    }

    .carousel-control .glyphicon-chevron-left:before {
      margin-left: 0;
    }

    .carousel-control .glyphicon-chevron-right:before {
      //Reset the icon
      content: " ";
      //Give layout
      display: block;
      //Your image as background
      background: url('http://fer.quiltcms-dev-2.fer.hr/_pub/themes_static/fer2016/default/img/caret-right.png') no-repeat;
      //To show full image set the dimensions
      width: 35px;
      height: 125px;
    }

    .carousel-control .glyphicon-chevron-left:before {
      //Reset the icon
      content: " ";
      //Give layout
      display: block;
      //Your image as background
      background: url('http://fer.quiltcms-dev-2.fer.hr/_pub/themes_static/fer2016/default/img/caret-left.png') no-repeat;
      //To show full image set the dimensions
      width: 35px;
      height: 125px;
    }

    .bootstrap_carousel .carousel-control span {
      /* margin: 0 !important; */
      /* top: 0; */
      //top: 50% !important;
      margin: auto !important;
    }

    .carousel-caption {
      left: 0 !important;
      right: 0 !important;
      margin-top: 20px;
    }

    .carousel-indicators {
      display: none;
    }

    .cms_module_html {
      //background-color: $portlet-bck;
    }

    .cms_module_html .events .carousel {
      height: 170px !important;
    }

    .cms_module_title {
      margin-top: -50px;
      background-color: white;
    }

    .cms_module_html .events {
      padding: 20px;
      vertical-align: middle;
      height: 210px;
      margin-top: 20px;
    }
  }
} //END portlet_event_displaycurrent in orange area

//naslovnica blue area
#area_blue_area .portlet_content .naslovnica {
  color: white !important;
  
  h2,  p {
    color: white !important;
  }
  
  li, p {
    font-size: 22px;

    @media (max-width: map-get($grid-breakpoints, md)) {
      font-size: 20px !important;
    }

    @media (max-width: map-get($grid-breakpoints, sm)) {
      font-size: 18px !important;
    }
  }
  p {
    width: 90%;

    @media (max-width: map-get($grid-breakpoints, md)) {
      width: 100%;
    }
  }
  h2 {
    width: 80%;
    font-size: 40px;

    @media (max-width: map-get($grid-breakpoints, md)) {
      width: 100%;
      font-size: 30px !important;
    }

    @media (max-width: map-get($grid-breakpoints, sm)) {
      width: 100%;
      font-size: 30px !important;
    }
  }
  li {
    display: inline-flex;
  }
  li img {
    position: relative;
    top: 10px;
    right: 15px;
  }

  ul {
    @media (max-width: map-get($grid-breakpoints, md)) {
      padding-top: 30px;
    }
  }
  .middle_li {
    padding: 50px 0;


    @media (max-width: map-get($grid-breakpoints, md)) {
      padding: 30px 0;
    }
  }
}
//end naslovnica blue area


//naslovnica 17 ciljeva UN fotografija

.image-wrapper {
  position: relative;
  background: black;
}

.image-wrapper img {
  display: block;
  max-width: 100%;
  height: auto;
  width: 100%;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    transform: scale(1.7) translateX(-17%);
    padding: 30px 0;
    	
    -webkit-mask-image: linear-gradient(to top, transparent 16%, black 50%);
    mask-image: linear-gradient(to top, transparent 16%, black 50%);
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    transform: scale(1.5) translateX(-17%);
  }
  
}





.text-overlay {
  position: absolute;
  top: 20%; /* Visina gumba od vrha slike */
  color: white;
  padding: 10px 20px 10px 0;
  font-size: 40px;
  font-weight: bold;
  width: 600px;

  @media (max-width: map-get($grid-breakpoints, xxl)) {
    font-size: 36px;
  }

  @media (max-width: map-get($grid-breakpoints, xl)) {
    width: 500px;
    font-size: 32px;
  }
  @media (max-width: map-get($grid-breakpoints, md)) {
    position: relative;
    bottom: 50px;
    width: 100%;
    top: 30%; 

  }
}
.btn-overlay {
  position: absolute;
  top: 60%; /* Visina gumba od vrha slike */
  background-color: black; /* Boja gumba */
  color: white;
  padding: 20px 0;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  border: 2px solid white;

  @media (max-width: map-get($grid-breakpoints, xxl)) {
    top: 75%;
  }
  @media (max-width: map-get($grid-breakpoints, md)) {
    position: relative;
    bottom: 50px;
    top: 70%;
  }
}

 .btn-overlay:hover {
  background-color: white; /* Boja gumba na hover */
  color: black;
  text-decoration: none;
}
//end naslovnica 17 ciljeva UN fotografija

a.rounded-pill {
  font-size: 14px;
  font-weight: 600;
  padding: 14px 25px;
  border: 2px solid;
}

.yellow_background_on_foto {
  background: #ECB000;
  padding: 50px 80px;
  color: black !important;
  position: relative;
  left: -170px;
  top: 53px;
  width: 127%;

  @media (max-width: 1400px) {
    left: -45px;
    top: 7px;
    padding: 25px 30px;
    width: 100%;

  }

  @media (max-width: 991px) {
    left: -170px;
    top: 3px;
    padding: 5px;
  }

  @media (max-width: 767px) {
    position: unset;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    width: 100%;

    margin-top: -1rem;
  }

}

.istrazivanje_suradnja {
  margin-top: 10%;
}

.istrazivanje_suradnja a.rounded-pill:hover {
    color: white !important;
    background-color: black;
    text-decoration: none;
}

.istrazivanje_suradnja p:first-of-type {
  margin-bottom: 51px;
}

.istrazivanje_suradnja .col-md-6.order-2 p:last-of-type {
  padding-top: 2.5rem;
}

.istrazivanje_suradnja h2 {
  margin-bottom: 2.5rem;
}

.yellow_background_on_foto a.rounded-pill:hover {
    color: #ECB000;
    background-color: black;
    text-decoration: none;
}

.yellow_background_on_foto p:first-of-type {
  margin-bottom: 40px;
}

#cms_area_middle3_full_width {
  padding-top: 5%;
}

.carousel_alt {
  margin-bottom: 5%;
  margin-top: 5%;

  .carousel-item, .carousel-inner {
    background-color: white !important;
  }

  .carousel-description-inner {
    padding-left: 0 !important;

    @media (max-width: map-get($grid-breakpoints, md)) {
      padding-left: 25px !important;
    }
  }
}

.middle2_area {
  .carousel .carousel-item, .carousel .carousel-inner {
    background-color: white !important
  }
  .carousel {
    margin: 5% 0;
  }

  margin-bottom: 85px;

  @media (max-width: map-get($grid-breakpoints, md)) {
    margin-bottom: 55px;
  }
}


.news_link_all {
  padding: 10px 54px;

  @media (max-width: map-get($grid-breakpoints, md)) {
    padding: 10px 25px;
  }

}
.news_link_all .module-title .cms_module_title {
  margin-right: 20px;
  font-weight: bold;
}
.news_link_all .module-title  {
  display: inline-block;
}
.news_link_all  {
  font-weight: bold;
}

.news_link_all .arrow-right {
  min-height: unset !important; 
  min-width: unset !important; 
  object-fit: unset !important;
}

.news_link_all a:hover {
  text-decoration: underline !important;
}

.page_title {
  display:none;
}


.bodycms_twocol_right .carousel .carousel-inner, .bodycms_twocol_right .carousel .carousel-item {
  background: #353A48 !important;
}

.bodycms_twocol_right .carousel  .carousel-description-inner {
  ///margin: 35% 0 0 0;
  //padding: 20% 54px 0 54px;
  //font-size: 40px;
  display: flex;
  align-items: center; /* Centriranje po vertikali */
  justify-content: center; /* Opcionalno - centriranje po horizontali */
  height: 100%; /* Osigurava da preuzima visinu roditelja */
}

.bodycms_twocol_right .carousel .carousel-description-inner .h1 {
  margin-bottom: 0;
}


.bodycms_twocol_right .portlet_content a:hover {
  text-decoration: underline !important;
}

.portlet_news_show_headlines  .carousel {

  background: white !important;

  .news_link_all h3 {
    font-size: 40px;
  }

  .carousel-date {
    font-size: 12px;
  }

  .carousel-description .carousel-description-inner h1 {
    font-size: 24px;
    letter-spacing: 0px;
    margin-bottom: 38px;
  }

  .carousel-indicators-prev-next {
    @media (max-width: 992px) {
      position: relative;
      justify-content: center;
      padding: 0 15px;
      margin: 20px 0;
    }
    @media (max-width: 576px) {
      justify-content: space-between;
    }
  }

}
.portlet_news_show_headlines  .carousel .col-lg {

  @media (max-width: map-get($grid-breakpoints, sm)) {
    padding-left: 0;
    padding-right: 0;

  }
}

a {
  text-decoration: none !important;
}

.fixed-top {
  height: 56px;
}

.kutija {
  margin-top: 20px;
}
  
.owl-item {
  text-align: unset;
}


#cms_area_horizontal_nav nav.main_nav .submenu ul {
 

  @media (min-width: map-get($grid-breakpoints, xl)) {
    width: 44%;
    margin: 0 auto;

  }
}

.portlet_djelatnici {
  .staff-row {
    .staff-thumbnails-container {
      min-height: 155px;
      //background-color:  var(--bs-primary);
      padding-top:10px;
      margin-bottom: 15px;
      border: 1px solid white;

      &:hover {
        //background-color: darken(@gray-lighter, 20%);
        border: 1px solid  var(--bs-primary);
      }
    }
    .title {
      font-size: 12px;
    }
    .name{
      display: inline-block;
    }

    .profile-pic {
      max-width: 50px;
      max-height: 65px;
    }
  }
}

@media (min-width: 2000px) {
  #area_carousel .carousel {
    max-width: 2000px;
    margin: 0 auto;
  }

  #cms_area_middle3_full_width {
    max-width: 2000px;
    margin: 0 auto;
    padding-top: 2%;
  } 

}


#unibox-invisible {
  position: absolute !important;
  /*important for fixing the input-group-addon heights*/
}

.dropdown-menu {
  border-width: 0 !important;
  border-radius: 0;
}


.unibox-selectable  {
 padding: 1px 8px !important;
 font-size: 12px !important;
} 

.unibox-selectable i {
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--bs-primary) !important;
}

.unibox-selectable .search_icon, .unibox-selectable .search_txt {
  display: inline-block;
}

.unibox-selectable .search_icon {
  width: 20px;
  color:var(--bs-primary);
}

.unibox-selectable .search_txt {
  width: 280px;
  vertical-align: middle;
  padding-left: 10px;
}

.unibox-selectable a {
  color: var(--bs-primary);
  line-height: 14px !important;

}

.unibox-selectable:hover {
  background-color:  var(--bs-primary) !important;
  color:  white !important;

   i {
    background-color:  var(--bs-primary) !important;
    color:  white !important;
  }
}

.unibox-selectable .unibox-highlight {
  color:  var(--bs-primary);
}

.unibox-suggest-cluster {
  margin-left: 5px;
  margin-right: 5px;
  border-bottom: 1px solid #b5b5b5;
  border-top: 1px solid #b5b5b5;
}

.unibox-suggest-cluster h4 {
  background-color: #b5b5b5;
  color: white;
  padding: 4px;
  display: none;
}

.unibox-suggest-cluster:last-child {
  border-bottom-width: 0;
}

.unibox-suggest-cluster:first-child {
  border-top-width: 0;
}

#unibox-suggest-box {
  max-height: 512px;
  overflow-y: auto !important;
}


.text-center.text-md-left.text-lg-left.text-xl-left {
  img {
    float: none;
  }

  .clear_both {
    text-align: center;
  }
}

.portlet_ieee_taxonomy_search .media-list .media {
  list-style: none;
}

.portlet_imenik {
  .imenik-row {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .results {
    margin-top:10px;
  }

  a:hover {
    text-decoration: underline !important;
  }
}


// mobilne stvari
.statistics_div p {
  @media (max-width: map-get($grid-breakpoints, md)) {
    margin-bottom: 35px;
  }
}
.statistics_div h2 {
  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 30px !important;
    margin-bottom: 35px;
  }
}

.text-center.text-md-left.text-lg-left.text-xl-left .clear_both {
  @media (max-width: map-get($grid-breakpoints, sm)) {
    text-align: center !important;
  }
}
.text-center.text-md-left.text-lg-left.text-xl-left img  {
  @media (max-width: map-get($grid-breakpoints, sm)) {
    float: none !important;
  }
}
.yellow_background_on_foto h2 {
  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 30px;
  }
}
.yellow_background_on_foto h2, .yellow_background_on_foto p {
  color: black !important;
}
.istrazivanje_suradnja h2 {
  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 30px;
    margin-right: 1.5rem;
  }
}

.black_area_links .col-md-6 p {
  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 18px !important;
    line-height: 24px !important;
  }
}



@media (max-width: map-get($grid-breakpoints, md)) {
  .carousel .carousel-inner img.img-fluid {
    height: 260px;
  }
}

.portlet_news_show_headlines .carousel .news_link_all h3 {
  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 30px;
    color: black !important;  
  }
}

.portlet_news_show_headlines .carousel .carousel-description .carousel-description-inner h1 {
  color: black !important;
}

.portlet_news_show_headlines .carousel-description .carousel-description-inner h1, .carousel-description .carousel-description-inner .h1 {
  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 20px
  }
}

.carousel_middle3_full_width {
  @media (min-width: map-get($grid-breakpoints, md)) {
    max-height: 500px;
    overflow: hidden;
  }
}



.bodycms_twocol_right .portlet_content h4 {
  text-align: left;
}

#portlet_news_show_headlines .carousel {
  @media (min-width: 2000px) {
   margin: 5% auto 0 auto;
  }
}


.middle3_area_white #cms_area_middle3_area_white .carousel {
    @media (min-width: 2000px) {
        margin: 5% auto 0 auto;
       }
}
.quilt_nav .container-fluid {
    @media (min-width: 2000px) {
        width: 2000px;
    }
}

.nav .flex-column {
    @media (min-width: 3000px) {
        width: 20%;
    }
}


@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
  #area_carousel .carousel h1 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; 
  }

  #area_carousel .carousel p {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; 
  
  }
}


.bodycms_twocol_right  .carousel .carousel-inner img.img-fluid {
  max-height: 500px;
}
.bodycms_twocol_right #area_carousel .carousel h1, .bodycms_twocol_right #no_carousel_page .carousel h1 {
  font-size: 50px;
  line-height: 55px;
  color: white;
  text-align: center;

  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 30px;
    line-height: 35px;
  }
}

.bodycms_twocol_right #area_carousel .carousel-indicators-prev-next {
  display: none;
}

.bodycms_twocol_right .twocol_right .row .col-xl-4 {
  @media (min-width: map-get($grid-breakpoints, lg)) {
      padding-left: 30px;
  }
}

.usefull_links a {
  color: white !important;
}

.usefull_links a:hover {
  text-decoration: underline !important;
}

.text-center.text-lg-left.text-md-left.text-xl-left {
  a:hover {
    text-decoration: underline !important;
  }
}

.portlet_news .hidden_content .form-inline {
  display: block ruby;
}

#bs-admin-navbar-collapse {
  ul.dropdown-menu > li > a {
    @media (max-width: map-get($grid-breakpoints, md)) {
      color: white;
    }
  }

  ul.dropdown-menu > li > a:hover {
    @media (max-width: map-get($grid-breakpoints, md)) {
      color: black;
    }
  }

  .dropdown-menu,  .dropdown-menu-right {
    @media (max-width: map-get($grid-breakpoints, md)) {
      background-color: #212529;
    }
  }
}

.portlet_news a:hover {
  text-decoration: underline !important;
}


#area_tabbed {
  //background: #f0f0f0;
  //padding: 40px 20px;
  .ui-widget {
    font-family: inherit !important;
    font-size: inherit !important;
  }

  .ui-tabs {
    background: transparent;
    .ui-tabs-panel {
      border: 0 !important;
      background: transparent;
    }
    .ui-tabs-nav {
      border: 0 !important;
      margin-right: 10%;
      padding: 0 !important;
      display: flex;
      justify-content: flex-end; /* Poravnava elemente desno */
      list-style: none; /* Uklanja defaultne oznake liste */
      li {
        margin: 0;
        border: 0;
        background: transparent;
        a {
          border: 0;
          font-size: 28px !important;
          line-height: 48px;
          //font-weight: bold;
          color: $primary;
          background-color: transparent !important;
          margin: 8px 30px;
          padding: 8px 0;
          border-bottom: 3px solid transparent;

        }
        &:not(.ui-state-active) a:hover {
          text-decoration: none;
          color: $secondary;
          //border-bottom: 3px solid $secondary;
          background-color: transparent !important;
        }
        &.ui-state-active a {
          //border-bottom: 3px solid $primary;
          font-weight: bold;
          position: relative; /* Omogućuje pozicioniranje strelice */

          /* Dodavanje strelice kao pozadinske slike */
          &::after {
              content: "";
              position: absolute;
              bottom: -10px; /* Postavlja strelicu ispod teksta */
              left: 15%;
              transform: translateX(-50%);
              width: 16px; /* Prilagodi širinu strelice */
              height: 16px; /* Prilagodi visinu strelice */
              background-image:url(../../../icons/strelica_down.svg); /* Putanja do strelice */
              background-size: contain;
              background-repeat: no-repeat;
          }
        }
      }
      @media (max-width: map-get($grid-breakpoints, md)) {
        display: flex;
        flex-direction: column;
       li a {
          padding: 4px 0 !important;
          margin: 0px 30px;
          //border-bottom: 2px solid transparent !important;
          font-size: 22px !important;

        }
        li:not(.ui-state-active) a:hover {
          //border-bottom: 2px solid $secondary !important;
        }
        li.ui-state-active a {
          //border-bottom: 2px solid $primary !important;
        }
      }
    }
  }

  #tabovi .container {
    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-top: 20px;
    }
  }

  a:hover {
    text-decoration: underline !important;
  }
}


.tabs_studiji {
  margin-top: 60px;
  @media (max-width: map-get($grid-breakpoints, lg)) {
    margin-top: 0;
  }

  h2 {
    font-size: 40px;
    line-height: 50px;
    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 28px;
    }
  }
  h5 {
    margin-bottom: 35px;
    font-size: 28px;
    line-height: 38px;
    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  .col-md-7 p{
    font-size: 22px;
    line-height: 40px;
    margin-bottom: 50px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      font-size: 18px;
      margin-bottom: 20px;
      line-height: 28px;
    }
  }

  .col-md-5 p{
    margin-bottom: 5px;
  }
 
}

.bodycms_onecol .lead_block {
  font-size: 28px;
  line-height: 48px;
}

