.usefull_links {
  padding: 120px 0px;
  background-color: #5A5F69;

  color: white !important;

  h2 {
    font-size: 40px;
    color: white;
  }

  p {
    font-size: 20px;
    color: white;
  }

  .statistics_div p:first-child {
    margin-top: 15px; 
    
    @media (max-width: map-get($grid-breakpoints, md)) {
      margin-top: 0;
    }
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: 40px 0px;
  }

  h3 {
    font-size: 30px;
    font-weight: 600;
    margin-top: 13px;
    color: var(--bs-secondary);
    max-width: 114px;


    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-top: 13px;
      max-width: unset;
    }
  }

  ul {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    display: block;

    @media (max-width: map-get($grid-breakpoints, xl)) {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
    }

    li {
      max-width: 241px;
      -moz-page-break-inside: avoid;
      -webkit-column-break-inside: avoid;
      break-inside: avoid;
      -o-column-break-inside: avoid;
      -ms-column-break-inside: avoid;
      column-break-inside: avoid;
      page-break-inside: avoid;
      img {
        margin-right: 15px;
        max-width: 13px;
        margin-bottom: 4px;
      }
      a {
        padding: 13px 0px;
        color: var(--bs-secondary);
        font-size: 16px;
        font-weight: 600;

        &:hover {
          color: var(--bs-primary);
        }
      }
    }
  }
}