
#cms_area_middle {
    .portlet_news {
        hr.news_separator {
            border-bottom: medium none;
            border-top: 1px solid #ccc;
            height: 0;
            margin: 0 0 10px;
            padding: 0;
        }


        .news_title h1 {
            font-size: 14px
        }

        .news_article {
            //border-bottom: 1px solid gray;
            padding-bottom: 2px;
            .news_lead_img_div.left {
                //display: none;
            }

            .news_lead_img_div.right {
                //display: none;
            }
        }

        .news_article_full {
            border-bottom: none;

            .news_lead_img_div.left {
                display: block;
                float:left;
            }

            .news_lead_img_div.right {
                display: block;
                img {
                    @extend .img-fluid;
                    margin-bottom: 5px;
                }
            }

            .news_article_left_col {
                display: block;
                img {
                    @extend .img-fluid;
                    margin-bottom: 5px;
                }
            }

            .chunks-end {
                clear: both;
            }

            .news_meta {
                margin: 10px 0px;
            }
        }

        .news_lead_img_div.left {
            float: left;
            img {
                margin-right: 20px;
            }
        }
        .news_lead_img_div.right {
            float: right;
            img {
                margin-left: 20px;
            }
        }

        .news_pub_date {
            //color: @brand-primary;
            float: left;

            .caption::after {
                content: ': ';
            }
        }

        .news_edit_date {
            color: gray;
            font-size: 12px;
            float: right;
            margin-right: 15px;
            margin-left: 15px;
            line-height: 30px;
            width: 190px;
        }

        .author {
            color: gray;
            float: right;
            margin-right: 35px;
            display: none;
        }

        .news_admin_links {
            text-align: center;
            padding-top: 15px;
        }

        .news_admin_links_top {
            .admin_link {
                line-height: 20px;
                i {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }

        .news-admin-row {
            border-bottom: 1px dotted gray;
            padding-bottom: 10px;
            margin-bottom: 10px;
        }

        .news-admin-search {
            margin-bottom: 10px;
        }

        .read_more_link {
            /*.btn;
            .btn-primary;
            .btn-xs;*/

            float: left;
            margin-right: 15px;
        }

        .back_link_container {
            text-align: center;
            display: block;
            a.back_link {
                padding: 6px 12px !important;
                /*.btn;
                .btn-default;
                .btn-xs;
                i:after {
                    content: '\00a0 ';
                }*/
            }
        }

        .news_options {
            float:right;
            clear:both;
        }

        .edit_news  {
            .edit_option {
                color: blue;
                text-decoration: underline;
                display: inline-block;
                cursor: pointer;
                margin: 0;
            }

            .check {
                position: relative;
                top: 4px;
            }

            .checked {
                position: relative;
                top: 4px;
            }

            .fixup {
                color: blue;
                text-decoration: underline;
                display: inline-block;
                cursor: pointer;
                margin: 0;
            }

            .line {
                display: inline;
            }

            .checked_pages {
                font-style: italic;
            }

            .col-md-9 > input {
                width: 100%;
                position:relative;
                top:-5px;
            }

            .form-group {
                padding-top: 10px;
            }

            .hasDatepicker  {
                width: 118px !important;
                margin-bottom: 10px;
            }

            input[type="checkbox"] {
                margin-right: 10px;

            }

            input[type="radio"] {
                margin-right: 10px;
            }
            .no_pic {
                max-width: 200px;
                max-height: 200px;
            }
        }
    }
}